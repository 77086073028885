import {  FETCH_REGIONS_REQUEST,FETCH_REGIONS_FAILURE,FETCH_REGIONS_SUCCESS,INIT_LOADER,HALT_LOADER,FETCH_REMARKS_FAILURE,
    FETCH_REMARKS_REQUEST,FETCH_REMARKS_SUCCESS, GET_PRTRCT_SETTINGS_FAILURE,GET_PRTRCT_SETTINGS_REQUEST,GET_PRTRCT_SETTINGS_SUCCESS,
    GET_CHAIN_INFO_REQUEST,GET_CHAIN_INFO_SUCCESS,
    GET_CHAIN_INFO_FAILURE,GET_CHAINBILL_INFO_REQUEST,GET_CHAINBILL_INFO_SUCCESS,GET_CHAINBILL_INFO_FAILURE,GET_STOREINFO_FAILURE,GET_STOREINFO_REQUEST,
    GET_STOREINFO_SUCCESS,GET_CHAINSTORELIST_FAILURE,GET_CHAINSTORELIST_REQUEST,GET_CHAINSTORELIST_SUCCESS,EDIT_STOREINFO,GET_SMS_SETTINGS_FAILURE,GET_SMS_SETTINGS_REQUEST,GET_SMS_SETTINGS_SUCCESS,UPDATE_SMS_SETTINGS
    ,GET_APP_SETTINGS_FAILURE,GET_APP_SETTINGS_REQUEST,GET_APP_SETTINGS_SUCCESS,UPDATE_APP_SETTINGS,FETCH_CUSTOMERS_FAILURE,FETCH_CUSTOMERS_SUCCESS,FETCH_CUSTOMERS_REQUEST,
    GET_LOYALTYINFO_FAILURE,GET_LOYALTYINFO_REQUEST,GET_LOYALTYINFO_SUCCESS,
    GET_LOYALTYPRODUCTS,GET_CONFIGLOYALTYPRODUCTS,UPDATE_LOYALTY_SETTINGS, GET_CHAINLISCENSES_REQUEST, GET_CHAINLISCENSES_SUCCESS, GET_CHAINLISCENSES_FAILURE,
    GET_SMS_RATES_REQUEST,GET_SMS_RATES_SUCCESS,GET_SMS_RATES_FAILURE,GET_PRINTER_TERMINAL,GET_WAREHOUSE_FAILURE,GET_WAREHOUSE_REQUEST,GET_WAREHOUSE_SUCCESS
    ,FETCH_ATTRIBUTES_REQUEST,FETCH_ATTRIBUTES_FAILURE,FETCH_ATTRIBUTES_SUCCESS,ALL_ATTRIBUTES,
    LOGOUT,CHECK_LISCENCEPROMO_SUCCESS,CHECK_LISCENCEPROMO_REQUEST,CHECK_LISCENCEPROMO_FAILURE,INFO_LISCENCEPAY_REQUEST,INFO_LISCENCEPAY_SUCCESS,INFO_LISCENCEPAY_FAILURE, CHECK_INDUSTRY_LIST_REQUEST,
    CHECK_INDUSTRY_LIST_SUCCESS,
    CHECK_INDUSTRY_LIST_FAILURE,
    GET_APPOINTMENTS_DATA_SUCCESS,
    GET_APPOINTMENTS_DATA_REQUEST,
    GET_APPOINTMENTS_DATA_FAILURE,
    GET_USERS_DATA_REQUEST,
    GET_USERS_DATA_SUCCESS,
    GET_USERS_DATA_FAILURE,
    GET_CITY_DATA,
    PRODUCT_DISCOUNT_REPORT_REQUEST,
    PRODUCT_DISCOUNT_REPORT_SUCCESS,
    PRODUCT_DISCOUNT_REPORT_FAILURE,
    GET_SYNC_TYPE_SUCCESS,
    FETCH_SYSTEMATTRIBUTES_FAILURE,
    FETCH_SYSTEMATTRIBUTES_REQUEST,
    FETCH_SYSTEMATTRIBUTES_SUCCESS,
    GET_COUNTERLIST_REQUEST,
    GET_COUNTERINFO_REQUEST,
    GET_COUNTERINFO_FAILURE,
    GET_EDITCOUNTERLIST_REQUEST,
    GET_EDITCOUNTERLIST_SUCCESS,
    GET_EDITCOUNTERLIST_FAILURE,
    GET_CREATECOUNTERLIST_REQUEST,
    GET_CREATECOUNTERLIST_SUCCESS,
    GET_CREATECOUNTERLIST_FAILURE,
    GET_COUNTERINFO_SUCCESS,
    FETCH_SALES_TARGET_PERIOD_REQUEST,
    FETCH_SALES_TARGET_PERIOD_SUCCESS,
    FETCH_SALES_TARGET_PERIOD_FAILURE,
    FETCH_SALES_TARGET_CONFIGS_REQUEST,
    FETCH_SALES_TARGET_CONFIGS_SUCCESS,
    FETCH_SALES_TARGET_CONFIGS_FAILURE,
    FETCH_SOURCE_USER_REQUEST,
    FETCH_SOURCE_USER_SUCCESS,
    FETCH_SOURCE_USER_FAILURE,
    GET_ALL_STORE_PRODUCTS_REQUEST,
    GET_ALL_STORE_PRODUCTS_SUCCESS,
    GET_ALL_STORE_PRODUCTS_FAILURE,
    GET_DEVICE_LOGS_REQUEST,
    GET_DEVICE_LOGS_SUCCESS,
    GET_DEVICE_LOGS_FAILURE,
} from '../actions/types';
import moment from 'moment';

const getAttributeStatus = {
    data:[],
    isLoading:null
}

const getAllAttributesStatus = {
    data:[],
    isLoading:null
}

const getSystemAttributeStatus = {
    data:[],
    isLoading:null
}


const getRegionsStatus =  {
    data:[],
    isLoading:null
}

const getPrintSettingStatus =  {
    data:[],
    isLoading:null
}

const getSmsSettingStatus =  {
    data:[],
    isLoading:null
}

const smsRatesStatus =  {
    data:[],
    isLoading:null
}

const getChainInfoStatus =  {
    data:[],
    isLoading:null
}

const getChainBillInfoStatus =  {
    data:[],
    isLoading:null
}

const getStoreInfoStatus =  {
    data:[],
    isLoading:null
}

const getWarehouseInfoStatus={
    data:[],
    isLoading:null
}

const getChainStoreListInfoStatus =  {
    data:[],
    isLoading:null
}

const getAppSettingStatus =  {
    data:[],
    isLoading:null
}

const getDeviceLogStatus =  {
    data:[],
    isLoading:null
}

const getPrinterTerminalStatus={
    data:[],
    isLoading:null
}

const getCustomersStatus= {
    data:[],
    isLoading:null
}

const getLoyaltyInfoStatus={
    data:[],
    isLoading:null
}

const getLoyaltyProductsStatus={
    data:[],
    isLoading:null
}

const getConfigLoyaltyProductsStatus={
    data:[],
    isLoading:null
}

const getChainLiscensesStatus={
    data:[],
    isLoading:null
}

const getLicensePromoStatus={
    data:[],
    isLoading:null
}

const getPaymentInfoStatus={
    data:[],
    isLoading:null
}

const getIndustryListStatus = {
    data:[],
    isLoading:null
}

const getAppointsments = {
    data:[],
    isLoading:null
}

const getAppointsmentsUser = {
    data:[],
    isLoading:null
}

const getProductDiscount = {
    data:[],
    isLoading:null
}


const getCityData = {
    data:[]
}

const getSyncType = {
    data:[]
}

const getCounterListData = {
    data:[],
    isLoading:null
}

const editedCounterListData = {
    data:[],
    isLoading:null
}
const createdCounterListData = {
    data:[],
    isLoading:null
}
const salesTargetPeriodListData = {
    data:[],
    isLoading:null
}

const salesTargetConfigsData = {
    data:[],
    isLoading:null
}

const sourceUserData = {
    data:[],
    isLoading:null
}

const storeWiseAllProd = {
    data:[],
    isLoading:null
}

export const fetchRegions= (state = getRegionsStatus, { type, payload }) => {
    switch (type){
        case FETCH_REGIONS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_REGIONS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case FETCH_REGIONS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const getWarehouses= (state = getWarehouseInfoStatus, { type, payload }) => {
    switch (type){
        case GET_WAREHOUSE_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_WAREHOUSE_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_WAREHOUSE_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const printReceiptSettingsInfo= (state = getPrintSettingStatus, { type, payload }) => {
    switch (type){
        case GET_PRTRCT_SETTINGS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_PRTRCT_SETTINGS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_PRTRCT_SETTINGS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const chainBillingInfo= (state = getChainBillInfoStatus, { type, payload }) => {
    switch (type){
        case GET_CHAINBILL_INFO_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_CHAINBILL_INFO_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_CHAINBILL_INFO_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const chainInfo= (state = getChainInfoStatus, { type, payload }) => {
    switch (type){
        case GET_CHAIN_INFO_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_CHAIN_INFO_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_CHAIN_INFO_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const storeInfo= (state = getStoreInfoStatus, { type, payload }) => {
    switch (type){
        case GET_STOREINFO_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_STOREINFO_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_STOREINFO_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}












export const getCounterList= (state = getCounterListData, { type, payload }) => {
    switch (type){
        case GET_COUNTERINFO_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_COUNTERINFO_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_COUNTERINFO_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}


export const editCounterList= (state = editedCounterListData, { type, payload }) => {
    switch (type){
        case GET_EDITCOUNTERLIST_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_EDITCOUNTERLIST_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_EDITCOUNTERLIST_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

// export const createCounterList= (state = createdCounterListData, { type, payload }) => {
//     switch (type){
//         case GET_CREATECOUNTERLIST_REQUEST:
//             state.isLoading = true;
//             return {
//                 ...state
//             }
//             case GET_CREATECOUNTERLIST_SUCCESS:
//             state.isLoading = false;
//             state.data=payload;
//             return {
//                 ...state
//             }
//             case GET_CREATECOUNTERLIST_FAILURE:
//                 state.isLoading = false;
//                 return {
//                     ...state
//                 }
//         default:
//             return state;
//     }
// }















export const chainStoreList= (state = getChainStoreListInfoStatus, { type, payload }) => {
    switch (type){
        case GET_CHAINSTORELIST_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_CHAINSTORELIST_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_CHAINSTORELIST_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}


export const messageSettingsInfo= (state = getSmsSettingStatus, { type, payload }) => {
    switch (type){
        case GET_SMS_SETTINGS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_SMS_SETTINGS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_SMS_SETTINGS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const smsRatesInfo= (state = smsRatesStatus, { type, payload }) => {
    switch (type){
        case GET_SMS_RATES_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_SMS_RATES_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_SMS_RATES_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const getDevices= (state = getAppSettingStatus, { type, payload }) => {
    switch (type){
        case GET_APP_SETTINGS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_APP_SETTINGS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_APP_SETTINGS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const getDevicesLogs= (state = getDeviceLogStatus, { type, payload }) => {
    switch (type){
        case GET_DEVICE_LOGS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_DEVICE_LOGS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_DEVICE_LOGS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const appSettings = (state = getPrinterTerminalStatus, {type, payload}) => {
    switch (type) {
        case GET_PRINTER_TERMINAL:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const customers= (state = getCustomersStatus, { type, payload }) => {
    switch (type){
        case FETCH_CUSTOMERS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_CUSTOMERS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case FETCH_CUSTOMERS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const loyaltyInfo= (state = getLoyaltyInfoStatus, { type, payload }) => {
    switch (type){
        case GET_LOYALTYINFO_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_LOYALTYINFO_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_LOYALTYINFO_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const loyaltyProducts = (state = getLoyaltyProductsStatus, {type, payload}) => {
    switch (type) {
        case GET_LOYALTYPRODUCTS:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const loyaltyAllProducts = (state = getConfigLoyaltyProductsStatus, {type, payload}) => {
    switch (type) {
        case GET_CONFIGLOYALTYPRODUCTS:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const chainLicenses= (state = getChainLiscensesStatus, { type, payload }) => {
    switch (type){
        case GET_CHAINLISCENSES_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_CHAINLISCENSES_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_CHAINLISCENSES_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const attributes= (state = getAttributeStatus, { type, payload }) => {
    switch (type){
        case FETCH_ATTRIBUTES_REQUEST:
            state.data=[]
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_ATTRIBUTES_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case FETCH_ATTRIBUTES_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const allAttributes= (state = getAllAttributesStatus, { type, payload }) => {
    switch (type){
        case ALL_ATTRIBUTES:
            state.isLoading = true;
            state.data=payload;
            return {
                ...state
            }
        case LOGOUT:
            return {
                data:[],
                isLoading:null
            };
        default:
            return state;
    }
}

export const fetchSystemAttributes= (state = getSystemAttributeStatus, { type, payload }) => {
    switch (type){
        case FETCH_SYSTEMATTRIBUTES_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_SYSTEMATTRIBUTES_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case FETCH_SYSTEMATTRIBUTES_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}



export const checkLicensePromo= (state = getLicensePromoStatus, { type, payload }) => {
    switch (type){
        case CHECK_LISCENCEPROMO_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case CHECK_LISCENCEPROMO_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case CHECK_LISCENCEPROMO_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const savePaymentInfo= (state = getPaymentInfoStatus, { type, payload }) => {
    switch (type){
        case INFO_LISCENCEPAY_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case INFO_LISCENCEPAY_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case INFO_LISCENCEPAY_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}


export const getIndustryListWeb= (state = getIndustryListStatus, { type, payload }) => {
    switch (type){
        case CHECK_INDUSTRY_LIST_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case CHECK_INDUSTRY_LIST_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case CHECK_INDUSTRY_LIST_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const GetAppointmentsDetails= (state = getAppointsments, { type, payload }) => {
    switch (type){
        case GET_APPOINTMENTS_DATA_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_APPOINTMENTS_DATA_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_APPOINTMENTS_DATA_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const GetUserDetails= (state = getAppointsmentsUser, { type, payload }) => {
    switch (type){
        case GET_USERS_DATA_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_USERS_DATA_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_USERS_DATA_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const GetCityDetails= (state = getCityData, { type, payload }) => {
    switch (type){
        
        case GET_CITY_DATA:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const GetSyncTypeData= (state = getSyncType, { type, payload }) => {
    switch (type){
        case GET_SYNC_TYPE_SUCCESS:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const salesAssessmentPeriods = (state = salesTargetPeriodListData,{type,payload}) =>{
    switch (type){
        case FETCH_SALES_TARGET_PERIOD_REQUEST:
            state.isLoading = true;
            state.data=[];
            return {
                ...state
            }
            case FETCH_SALES_TARGET_PERIOD_SUCCESS:
            state.isLoading = false;
           let data= payload&&payload.length&&payload.map((el)=>{
               el['label']=`${el.periodName} (${moment(el.startDate).format('Do MMM YYYY')} - ${moment(el.endDate).format('Do MMM YYYY')})`
               el['value']=el.ID
               return el;
            })
            state.data=data||[];
            return {
                ...state
            }
            case FETCH_SALES_TARGET_PERIOD_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const salesTagetConfigs = (state = salesTargetConfigsData,{type,payload}) =>{
    switch (type){
        case FETCH_SALES_TARGET_CONFIGS_REQUEST:
            state.isLoading = true;
            state.data=[];
            return {
                ...state
            }
            case FETCH_SALES_TARGET_CONFIGS_SUCCESS:
            state.isLoading = false;
            state.data=payload
            return {
                ...state
            }
            case FETCH_SALES_TARGET_CONFIGS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const soucreWiseUsers = (state = sourceUserData,{type,payload}) =>{
    switch (type){
        case FETCH_SOURCE_USER_REQUEST:
            state.isLoading = true;
            state.data=[];
            return {
                ...state
            }
            case FETCH_SOURCE_USER_SUCCESS:
            state.isLoading = false;
            state.data=payload
            return {
                ...state
            }
            case FETCH_SOURCE_USER_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const getStoreWiseAllProds = (state = storeWiseAllProd,{type,payload}) =>{
    switch (type){
        case GET_ALL_STORE_PRODUCTS_REQUEST:
            state.isLoading = true;
            state.data=[];
            return {
                ...state
            }
            case GET_ALL_STORE_PRODUCTS_SUCCESS:
            state.isLoading = false;
            state.data=payload
            return {
                ...state
            }
            case GET_ALL_STORE_PRODUCTS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

